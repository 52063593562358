import { useState, useEffect } from "react";
import GetStarted from "./steps/onboarding/getStarted";
import PhoneNumber from "./steps/onboarding/phoneNumber";
import QRCode from "./steps/onboarding/qrCode";
import OnboardingComplete from "./steps/onboarding/complete";
import AuthMethod from "./steps/authMethod";
import AuthRejected from "./steps/rejected";
import SMSCode from "./steps/smsCode";
import WaitingCall from "./steps/waitingCall";
import WaitingPush from "./steps/waitingPush";
import Loading from "./steps/loading";
import { CreateTenant } from "./utilities/api";
import { useSearchParams } from "react-router-dom";
import LoginSuccess from "./steps/loginSuccess";

function App({ enroll, auth }) {
  const [step, setStep] = useState(1);
  const [accountId, setAccountId] = useState("");
  const [qrCodeURI, setQrCodeURI] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [requestId, setRequestId] = useState("");
  const [returnUrl, setReturnUrl] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  //http://localhost:3000/enroll?tx=f1b776cb-2647-46e9-a334-bf0f31d0c85b&return=https%3A%2F%2Fwpmfa.hyparauth.app%2F&uid=3&ue=dino%40dd9.dev

  useEffect(() => {
    let inView = true;
    if (inView) {
      setAccountId(searchParams.get("tx"));
      setReturnUrl(searchParams.get("return"));
      setUserId(searchParams.get("uid"));
      if (enroll) {
        setUserEmail(searchParams.get("ue"));
        setTimeout(() => setStep(2), 700);
      } else if (auth) {
        setTenantId(searchParams.get("tid"));
        setTimeout(() => setStep(6), 700);
      }
    }
    return () => {
      inView = false;
    };
  }, []);

  const validateData = (data) => {
    //
  };

  const renderStep = () => {
    if (step === 1) {
      return <Loading />;
    } else if (step === 2) {
      return <GetStarted nextStep={nextStep} />;
    } else if (step === 3) {
      return <PhoneNumber nextStep={nextStep} />;
    } else if (step === 4) {
      return <QRCode nextStep={nextStep} qrLink={qrCodeURI} />;
    } else if (step === 5) {
      return <OnboardingComplete nextStep={nextStep} />;
    } else if (step === 6) {
      return (
        <AuthMethod
          nextStep={nextStep}
          tenantId={tenantId}
          accountId={accountId}
        />
      );
    } else if (step === 7) {
      return <AuthRejected nextStep={nextStep} />;
    } else if (step === 8) {
      return (
        <SMSCode
          nextStep={nextStep}
          requestId={requestId}
          accountId={accountId}
        />
      );
    } else if (step === 9) {
      let callbackUrl = wp_callback();
      return <LoginSuccess callbackUrl={callbackUrl} />;
    }
  };

  const nextStep = (data) => {
    if (step === 2 || step === 5) {
      setStep(step + 1);
    } else {
      if (step === 3) {
        handlePhoneNumber(data);
      } else if (step === 4) {
        setStep(5);
      } else if (step === 6) {
        if (data.complete) {
          if (data.requestId) {
            setRequestId(data.requestId);
            setTenantId(data.tenantId);
            setTimeout(() => (window.location.href = wp_callback()), 700);
          } else {
            setStep(7);
          }
        } else if (!data.failed) {
          setRequestId(data.requestId);
          setTimeout(() => setStep(8), 750);
        } else {
          setStep(7);
        }
      } else if (step === 7) {
        window.location.href = returnUrl;
      } else if (step === 8) {
        if (data.complete) {
          setTimeout(() => (window.location.href = wp_callback()), 700);
        } else {
          setStep(7);
        }
      } else if (step === 9) {
        window.location.href = wp_callback();
      } else if (step === 10) {
        // handleWaitingPush();
      }
    }
  };

  const wp_callback = () => {
    let toSend = returnUrl + `/hyp3rauth?tx=${requestId}&ue=${userId}`;
    return toSend;
  };

  const handlePhoneNumber = (data) => {
    CreateTenant({ phoneNumber: data.phoneNumber, email: "" }, accountId)
      .then((res) => {
        let link = res.data.link;
        setQrCodeURI(link);
        let tenantId = res.data.tenantId;
        setTenantId(tenantId);
        setTimeout(() => setStep(4), 700);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "#181818",
        backgroundColor: "rgb(4, 120, 87)",
        backgroundImage:
          "radial-gradient(at 46% 43%, rgb(96, 165, 250) 0, transparent 98%), radial-gradient(at 41% 50%, rgb(191, 219, 254) 0, transparent 65%), radial-gradient(at 89% 13%, rgb(165, 243, 252) 0, transparent 36%), radial-gradient(at 70% 20%, rgb(6, 78, 59) 0, transparent 12%), radial-gradient(at 29% 60%, rgb(245, 245, 244) 0, transparent 97%), radial-gradient(at 23% 64%, rgb(167, 139, 250) 0, transparent 8%)",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <div
          style={{
            width: 720,
            height: 440,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            background: "rgba(255, 255, 254, 0.5)",
            borderRadius: 20,
            gap: 20,
          }}
        >
          {step === 1 ? (
            <Loading />
          ) : (
            <>
              {step !== 4 && step !== 9 ? (
                <div
                  style={{
                    width: 180,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://i.imgur.com/XN0b9Vf.png"
                    style={{
                      width: "90%",
                      height: "90%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              ) : null}
              {renderStep()}
            </>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 45,
          background: "rgba(255,255,255,0.1)",
          backdropFilter: "blur(50px)",
          filter: "drop-shadow(0 -1mm 8mm rgb(0, 0, 0))",
          color: "#181818",
        }}
      >
        <p style={{ fontSize: 14 }}>
          Powered by{" "}
          <a
            href="https://hyperauth.app"
            style={{
              textDecoration: "none",
              color: "#181818",
              fontWeight: "bold",
            }}
            target="_blank"
          >
            HyperAuth
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
