import instance from "./instance";

export const CreateTenant = async (data, accountId) => {
  return await instance.post("/wp/createTenant", data, {
    headers: { accountId: accountId },
  });
};

export const AuthTenant = async (data, accountId) => {
  return await instance.post("/wp/authTenant", data, {
    headers: { accountId: accountId },
  });
};

export const CheckAuth = async (data, accountId) => {
  return await instance.post("/wp/checkAuth", data, {
    headers: { accountId: accountId },
    timeout: 45000,
  });
};

export const SMSAuth = async (data, accountId) => {
  return await instance.post("/wp/smsAuth", data, {
    headers: { accountId: accountId },
    timeout: 45000,
  });
};
