import QRCodeGenerator from "../../components/qrCodeGenerator";
import styled from "styled-components";
const QRCode = ({ qrLink, nextStep }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        paddingLeft: 10,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          width: "65%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 10,
          gap: 10,
        }}
      >
        <p style={{ fontSize: 18 }}>Activate Your Account</p>
        <p style={{ fontSize: 14 }}>
          Download and install the HyperAuth Application on your mobile device,
          then, after clicking on Get Started, select the “I have a QR code to
          scan” option, and scan the code on the right.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            margin: "0 auto",
            marginTop: 10,
          }}
        >
          <CustomButton onClick={() => nextStep()}>Continue</CustomButton>
        </div>
      </div>
      <div
        style={{
          width: "35%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <QRCodeGenerator QRData={qrLink} />
      </div>
    </div>
  );
};

const CustomButton = styled.p`
  padding: 8px 25px;
  background: black;
  color: white;
  cursor: pointer;
  border: 1px solid black;
  :hover {
    background: transparent;
    color: black;
    transition: 0.3s;
    ease-in-out;
  }
`;

export default QRCode;
