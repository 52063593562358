import Lottie from "lottie-react";
import animationData from "../assets/loading.json";

const Loading = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ width: "20%", height: "20%" }}
      />
      <p style={{ fontSize: 18, color: "#181818", fontWeight: "bold" }}>
        LOADING
      </p>
    </div>
  );
};
export default Loading;
