import { useState } from "react";
import ReactCodeInput from "react-code-input";
import styled from "styled-components";
import { SMSAuth } from "../utilities/api";

const SMSCode = ({ nextStep, requestId, accountId }) => {
  const [code, setCode] = useState("");

  const submitCode = () => {
    if (code.length === 8) {
      SMSAuth({ requestId: requestId, token: code }, accountId)
        .then((res) => {
          if (res.data.status === "Authenticated") {
            nextStep({
              complete: true,
              requestId: requestId,
              tenantId: res.data.tenantId,
            });
          } else {
            nextStep({ complete: false, failed: true });
          }
        })
        .catch((err) => {
          nextStep({ complete: false, failed: true });
        });
    } else {
      alert("Please enter a valid code");
    }
  };

  return (
    <div
      style={{
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
        paddingRight: 20,
        gap: 10,
      }}
    >
      <p style={{ fontSize: 18 }}>Enter the code we just texted you</p>
      <ReactCodeInput
        type="text"
        fields={8}
        // style={{ margin: "0 auto" }}
        onChange={(v) => setCode(v)}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          marginTop: 10,
        }}
      >
        <CustomButton onClick={() => submitCode()}>Submit</CustomButton>
      </div>
    </div>
  );
};

const CustomButton = styled.p`
  padding: 5px 25px;
  background: black;
  color: white;
  cursor: pointer;
  border: 1px solid black;
  :hover {
    background: transparent;
    color: black;
    transition: 0.3s;
    ease-in-out;
  }
`;

export default SMSCode;
