import styled from "styled-components";

const getStarted = ({ nextStep }) => {
  return (
    <div
      style={{
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        gap: 10,
        paddingRight: 20,
      }}
    >
      <p style={{ fontSize: 18 }}>Introducing HyperAuth MFA</p>
      <p style={{ fontSize: 12 }}>
        To protect your account and data, your organization has implemented
        HyperAuth as a required multi-factor authentication service. Let's get
        you started by setting up your account for it.
      </p>
      <div
        style={{
          width: "95%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "0 auto",
          marginTop: 10,
        }}
      >
        <CustomButton onClick={() => nextStep()}>Get Started</CustomButton>
      </div>
    </div>
  );
};

const CustomButton = styled.p`
  padding: 8px 25px;
  background: black;
  color: white;
  cursor: pointer;
  border: 1px solid black;
  :hover {
    background: transparent;
    color: black;
    transition: 0.3s;
    ease-in-out;
  }
`;

export default getStarted;
