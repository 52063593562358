import { useState } from "react";
import styled from "styled-components";
import { AuthTenant, CheckAuth } from "../utilities/api";

const CustomButton = styled.p`
  padding: 5px 25px;
  background: black;
  color: white;
  cursor: pointer;
  border: 1px solid black;
  :hover {
    background: transparent;
    color: black;
    transition: 0.3s;
    ease-in-out;
  }
`;

const AuthMethod = ({ nextStep, tenantId, accountId }) => {
  const triggerAuth = (method) => {
    let data = { tenantId: tenantId, method: method };
    AuthTenant(data, accountId)
      .then((res) => {
        let requestId = res.data.requestId;
        if (method === "SMS") {
          nextStep({
            requestId: requestId,
            tenantId: tenantId,
            complete: false,
          });
        } else {
          setTimeout(() => {
            checkStatus(requestId);
          }, 3000);
        }
      })
      .catch((err) => {
        nextStep({ failed: true });
      });
  };

  const checkStatus = (requestId) => {
    CheckAuth({ requestId: requestId }, accountId)
      .then((res) => {
        nextStep({
          requestId: requestId,
          tenantId: res.data.tenantId,
          complete: true,
        });
      })
      .catch((err) => {
        nextStep({ failed: true });
      });
  };

  return (
    <div
      style={{
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        gap: 10,
        paddingRight: 20,
      }}
    >
      <p style={{ fontSize: 18 }}>Pick an Authentication Method</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          justifyContent: "flex-start",
          width: "100%",
          marginTop: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>HyperPush Notification</p>
          <CustomButton onClick={() => triggerAuth("Push")}>
            Select
          </CustomButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>Phone Call</p>
          <CustomButton onClick={() => triggerAuth("Voice")}>
            Select
          </CustomButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p>SMS With a Code</p>
          <CustomButton onClick={() => triggerAuth("SMS")}>Select</CustomButton>
        </div>
      </div>
    </div>
  );
};
export default AuthMethod;
