import styled from "styled-components";
import { useState } from "react";

const PhoneNumber = ({ nextStep }) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const validatePhone = () => {
    if (phoneNumber.length === 10) {
      nextStep({ phoneNumber });
    } else {
      alert("Please enter a valid phone number");
    }
  };

  return (
    <div
      style={{
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        paddingRight: 20,
        gap: 10,
      }}
    >
      <p style={{ fontSize: 18 }}>Enter Your Phone Number</p>
      <input
        type="tel"
        placeholder="Phone Number"
        style={{
          width: "95%",
          margin: "0 auto",
          height: 35,
          padding: "5px 12px",
          borderRadius: 5,
          background: "rgba(255, 255, 254, 0.5)",
          outline: "none",
          border: "none",
          fontSize: 16,
        }}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "0 auto",
          marginTop: 5,
        }}
      >
        <CustomButton onClick={() => validatePhone()}>Continue</CustomButton>
      </div>
    </div>
  );
};

const CustomButton = styled.p`
  padding: 8px 25px;
  background: black;
  color: white;
  cursor: pointer;
  border: 1px solid black;
  :hover {
    background: transparent;
    color: black;
    transition: 0.3s;
    ease-in-out;
  }
`;
export default PhoneNumber;
