import styled from "styled-components";

const CustomButton = styled.p`
  padding: 8px 25px;
  background: black;
  color: white;
  cursor: pointer;
  border: 1px solid black;
  :hover {
    background: transparent;
    color: black;
    transition: 0.3s;
    ease-in-out;
  }
`;

const OnboardingComplete = ({ nextStep }) => {
  return (
    <div
      style={{
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        gap: 10,
        paddingRight: 15,
      }}
    >
      <p style={{ fontSize: 18 }}>Try It Out</p>
      <p style={{ fontSize: 12 }}>
        Now that you’ve added your account, let’s try out HyperAuth. This will
        be the same experience you will be presented with every time you log
        into your Wordpress Admin Panel.
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          margin: "0 auto",
          marginTop: 10,
        }}
      >
        <CustomButton onClick={() => nextStep()}>Continue</CustomButton>
      </div>
    </div>
  );
};
export default OnboardingComplete;
