import { useEffect } from "react";
const LoginSuccess = ({ nextStep }) => {
  useEffect(() => {
    setTimeout(() => nextStep(), 3000);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      }}
    >
      <p style={{ fontSize: 22 }}>Login Success</p>
      <p style={{ fontSize: 14 }}>
        Your Login Request was successfully approved.
      </p>
      <p style={{ fontSize: 14 }}>You will be redirected shortly.</p>
    </div>
  );
};
export default LoginSuccess;
