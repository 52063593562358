import { useRef, useEffect, useState } from "react";
import QRCodeStyling from "qr-code-styling";

const QRCodeGenerator = ({ QRData }) => {
  const [url, setUrl] = useState("");
  const ref = useRef(null);
  useEffect(() => {
    setUrl(QRData);
    qrCode.append(ref.current);
  }, []);
  useEffect(() => {
    qrCode.update({ data: QRData });
  }, [url]);
  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    data: QRData,
    margin: 0,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.5, margin: 2 },
    dotsOptions: { type: "dots", color: "#3d4044" },
    backgroundOptions: { color: "#ffffff" },
    image: "https://i.imgur.com/Egp7Ofx.png",
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "square", color: "#000000" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#f20c2e" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  });
  return <div ref={ref} />;
};

export default QRCodeGenerator;
