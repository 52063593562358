import { useEffect } from "react";
const AuthRejected = ({ nextStep }) => {
  useEffect(() => {
    setTimeout(() => nextStep(), 3000);
  }, []);
  return (
    <div
      style={{
        width: "70%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10,
        gap: 10,
        paddingRight: 20,
      }}
    >
      <p style={{ fontSize: 18 }}>Access Denied</p>
      <p style={{ fontSize: 12 }}>
        Your Login Request was denied. Your location and IP address have been
        sent to the website administrator. If this was a mistake, please try
        again.
      </p>
    </div>
  );
};
export default AuthRejected;
